import React from 'react';
import { render } from 'react-dom';

import { ready } from 'core-utilities';

import App from './App';
import PrivateServerList from './containers/PrivateServerList';

import '../../../css/serverList/serverList.scss';

const serverListContainerId = 'running-game-instances-container';
const privateServerAboutTabContainerId = 'private-server-container-about-tab';

ready(() => {
  if (document.getElementById(privateServerAboutTabContainerId)) {
    render(
      <PrivateServerList showServers={false} />,
      document.getElementById(privateServerAboutTabContainerId)
    );
  }

  if (document.getElementById(serverListContainerId)) {
    render(<App />, document.getElementById(serverListContainerId));
  }
});
